body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body {
  height: 100%;
  margin: 0;
}

.innerPanel {
  cursor: pointer;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.float-right {
  float: right;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

a.link-secondary {
  color: #98a2b3;
  text-decoration: none;
}
.bg-transparent {
  background: transparent !important;
}
.cursor {
  cursor: pointer;
}

.invalidate-box {
  border: solid 1px #ed3451 !important;
}
.invalidate-text {
  color: #ed3451 !important;
}
.full-page {
  height: 100vh;
  background: #0c1019;
}
.full-page-non-bg {
  height: 100vh;
  background: white;
}
.pull-page-bg-secondary {
  height: 100vh;
  background: #f9fafc;
  overflow: hidden;
}

.line-height-2 {
  line-height: 2;
}

.item-center {
  align-items: center;
}
.box-shadow-center {
  box-shadow: 1px 0px 15px 0px rgb(0 0 0 / 11%);
}

/*=== Trigger  ===*/
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate-5 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/
.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.01s;
}

.two {
  -webkit-animation-delay: 0.001s;
  -moz-animation-delay: 0.001s;
  animation-delay: 0.001s;
}

.three {
  -webkit-animation-delay: 2.3s;
  -moz-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.four {
  -webkit-animation-delay: 3.3s;
  -moz-animation-delay: 3.3s;
  animation-delay: 3.3s;
}
/*==== HEIGHT UP ===*/
@-webkit-keyframes heightUp {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}
@keyframes heightUp {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}

.heightUp {
  -webkit-animation-name: heightUp;
  animation-name: heightUp;
}
/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/*=== FADE IN DOWN ===*/
@-webkit-keyframes fadeInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.spinner {
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0d6efd;
  animation: rotating-spinner 1s linear infinite;
}

.spinner-sm {
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0d6efd;
  animation: rotating-spinner 1s linear infinite;
}

@keyframes rotating-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadingbox-lg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9999999999;
  background: #00000026;
  justify-content: center;
}
.loadingbox-md {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  z-index: 9999999999;
  background: #ffffff00;
  justify-content: center;
}

/* Typography */
.text-30 {
  font-size: 30px !important;
}
.text-24 {
  font-size: 24px !important;
}
.text-20 {
  font-size: 20px !important;
}
.text-18 {
  font-size: 18px !important;
}
.text-16 {
  font-size: 16px !important;
}
.text-14 {
  font-size: 14px !important;
}
.text-12 {
  font-size: 12px !important;
}
.text-regular {
  font-weight: 400 !important;
}
.text-semibold {
  font-weight: 600 !important;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  margin: 0 1rem;
}

.arrow span {
  top: 0.5rem;
  position: absolute;
  width: 1px;
  height: 6px;
  background-color: #1c1b1f;
}
.arrow-span-left {
  left: 1px;
  transform: rotate(135deg);
}
.arrow-span-right {
  right: -6px;
  transform: rotate(-135deg);
}

.modal-box {
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 100vh;
  width: 82%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  top: 0% !important;
  left: 18% !important;
}
.modal-Publish-box {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 0px;
  border-radius: 10px;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
}
.modal-cfPublish-box {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 396px;
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutRight {
  from {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.ReactModal__Overlay {
  background-color: rgb(52 64 84 / 60%) !important;
}
.alert-success {
  background: #ecfdf3 !important;
  color: #027a48 !important;
}
.alert-danger {
  background: #fecdca !important;
  color: #d92d20 !important;
}
.min-h-82 {
  min-height: 82vh !important;
}
.text-lighter {
  color: #c2c2c2 !important;
}
.text-grey {
  color: #667085 !important;
}
.text-red {
  color: #f04438 !important;
}

.border-shadow {
  border: 1px solid #eaecf0;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 11%);
}
.h-35 {
  height: 35px !important;
}
.h-37 {
  height: 37px !important;
}
.h-44 {
  height: 44px !important;
}
.dropdown-item-setting {
  cursor: pointer;
  padding: 15px;
}

.icon-addon-input {
  position: absolute;
  margin: 10px;
}

.pl-40 {
  padding-left: 40px !important;
}

.icon-name-info {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  background: #f2f4f7;
  text-transform: uppercase;
  color: #475467;
  font-weight: 500;
}
.form-control:disabled {
  background-color: #f5f5f5 !important;
  opacity: 1;
  color: #6c757d !important;
  border: 1px solid #d0d5dd !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 29px;
}

.switch input {
  opacity: 0 !important;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #0047ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0047ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid #d0d5dd;
}

.slider.round:before {
  border-radius: 50%;
}
.-cursor {
  cursor: pointer;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: unset !important;
}
.text-10 {
  font-size: 10px;
}
.text-30 {
  font-size: 30px;
}
.text-36 {
  font-size: 36px;
}

.dropdown-white {
  background-color: #fff !important;
  border-color: #d0d5dd !important;
  color: #667085 !important;
  font-size: 14px !important;
}
.dropdown-white > svg {
  margin-top: -2px;
}
.dropdown-outline {
  background: none !important;
  border: unset !important;
  color: #667085 !important;
  font-size: 14px !important;
}

.dropdown-full {
  width: 100%;
}

.dropdown-toggle::after {
  display: none !important;
}
.mt-plan-box {
  margin-top: 30px;
}

.badge-outline-green {
  color: #12b76a !important;
  background-color: #fff;
  border: 1px solid #12b76a;
}
.worningzone {
  background-color: #fff0c6;
  border: 1px solid #fec84b;
  border-radius: 8px;
}
.cursor-no-drop {
  cursor: no-drop;
}
.mini-bar {
  height: 200px !important;
}
.dropdown-dot {
  background-color: #fff !important;
  color: #667085 !important;
  font-size: 14px !important;
  border: 0 !important;
}
.boxSetting-dot {
  right: 1%;
  width: 200px !important;
  text-align: left;
  border: 1px solid #0000000f;
  box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
}
.dropdown-item-setting-dot {
  cursor: pointer;
  padding: 10px !important;
}
.dropdown-item-setting-dot:hover {
  background: #eaf0ff !important;
}
.loading-sm {
  width: 25px !important;
}
.rdt_TableHeadRow {
  background-color: #f9fafb !important;
  color: #475467 !important;
  font-size: 12px !important;
}
.h-400 {
  min-height: 400px;
}
.d-center {
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.badge-md {
  margin-left: 0px;
  min-width: 55px;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  padding: 4px 6px;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.badge-md-info {
  background-color: #c3f1ff;
}
.badge-md-green {
  background-color: #ddffc3;
}
.badge-md-purple {
  background-color: #bec9ff;
}
.badge-md-pink {
  background-color: #ddc3ff;
}
.badge-md-Yellow {
  background-color: #fff3d2 !important;
  color: #f2c34a !important;
}
.set-table {
  min-height: 200px;
  max-height: 350px;
  overflow-y: auto;
}
.img-180 {
  width: 135px;
}
.border-card-center {
  border: 1px solid #f2f2f2;
  margin: 0px 3px;
}
.border-card-bottom {
  border-bottom: 1px solid #f2f2f2;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #ffffff !important;
  pointer-events: none;
  background-color: #d0d5dd !important;
  border-color: #d0d5dd !important;
  opacity: var(--bs-btn-disabled-opacity);
  font-weight: 600;
}

.drop-box {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pl-25em {
  padding-left: 2.5rem !important;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: #000 !important;
  --bs-table-bg-state: #f1f1f1 !important;
}
.td-sub-table {
  background-color: #f9f9f9 !important;
}

.w-table {
  max-width: 150px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d-grid-topbar {
  display: grid !important;
  align-content: center !important;
}
.d-item-center {
  align-items: center !important;
}
.w-55px {
  width: 55px;
}
.w-125 {
  width: 125px !important;
}
.w-140 {
  width: 140px !important;
}
.w-280 {
  width: 280px !important;
}
.text-blue {
  color: #0047ff;
}
.text-dark-gray {
  color: #344054;
}
.btn-h-44 {
  height: 44px !important;
  padding: 10px !important;
}
.table-border-radius {
  border-radius: 6px !important;
  border: 1px solid #f8f9fa;
}
.badge-primary-popup {
  background-color: #eaf0ff !important;
  color: #0047ff !important;
}

.badge-beta {
  background-color: #ffffff !important;
  color: #667085 !important;
  border: 1px solid #667085 !important;
  padding: 5px !important;
  margin-left: 20px !important;
}
.img-20 {
  width: 20px;
}
.w-160 {
  width: 160px !important;
}
.mr-2 {
  margin-right: 5px;
}
.mr-3 {
  margin-right: 10px;
}
.bg-most {
  background-color: #0047ff !important;
}
.badge-outline-blue {
  color: #0047ff;
  border-color: #0047ff;
}
.badge-outline-gold {
  color: #ffffff !important;
  border-color: #b9a884;
  background-color: #b9a884 !important;
}
.border-card-plan {
  border-radius: 10px !important;
  border-top: 5px solid #ddd !important;
}
.tracking-font {
  font-family: sans-serif !important;
  font-size: 14px !important;
}
.badge {
  text-align: center !important;
}

@media screen and (max-width: 1440px) {
  .content {
    min-height: 78vh;
  }
  .content-scroll {
    height: 88%;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1441px) {
  .content {
    min-height: 80vh;
  }
  .content-scroll {
    height: 100%;
    overflow-y: auto;
  }
}

.text-grey-italic {
  font-style: italic;
  color: #acb8cb;
}
.plan-box-border {
  border-color: #0047ff !important;
}

.text-grey-300 {
  color: #d0d5dd !important;
}
.cursor-no-drop {
  cursor: no-drop;
}
.w-300px {
  width: 300px !important;
}

.inputBubble {
  border: 1px solid #eaecf0;
  padding: 0.375rem 0.75rem;
  min-height: 135px;
  border-radius: 5px;
  padding-left: 32px;
}
.input-invite,
.input-invite:focus-visible,
.input-invite:focus {
  border-color: transparent;
  outline: none;
}
.max-height30 {
  height: 30px;
}
.search-box {
  width: 92%;
}

.loading-fullpage {
  display: grid;
  align-content: center;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.loading-progress-box {
  width: 286px;
}
.loading-logo-box {
  width: 230px !important;
}
.loading-progress-background {
  background-color: #6490fe !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.bg-coupon-box {
  background-color: #eaf0ff !important;
  border-radius: 7px;
}
::placeholder {
  color: #d0d5dd !important;
}
.pr-2 {
  padding-right: 5px !important;
}
.text-grey-600 {
  color: #475467;
}
.text-sky {
  color: #0099ff;
}
.pr-0 {
  padding-right: 0 !important;
}

.big-intro {
  max-width: 686px;
  width: 686px;
}
.introjsFloatingElement {
  top: 40% !important;
}

.min-footer {
  min-height: calc(100% - 72px);
}
.h-100 {
  height: 100% !important;
}
.min-h-95 {
  min-height: 95vh !important;
}
.min-h-100 {
  min-height: 100% !important;
  overflow-y: auto;
  max-height: 100%;
}
.img-24 {
  height: 24px;
}
.btn-gradient-authen {
  background: linear-gradient(90deg, #1774ff 1.32%, #8e22ff 100%);
  color: #fff !important;
}
.btn-gradient-authen:hover {
  background: linear-gradient(90deg, #002ea5 100%, #002ea5 100%);
  color: #fff !important;
  opacity: 0.9;
}
.introjs-button:hover {
  outline: 0;
  text-decoration: none;
  border-color: #0047ff;
  background-color: #0047ff;
  color: #fff;
}
.introjs-button {
  outline: 0;
  text-decoration: none;
  border-color: #0047ff;
  background-color: #0048fff1;
  color: #fff;
  text-shadow: none;
}
.introjs-button:focus {
  outline: 0;
  text-decoration: none;
  background-color: #eee;
  border-color: #0047ff;
  background-color: #0048fff1;
  color: #fff;
}
.min-h-90 {
  min-height: 90%;
}

.introjs-tooltip-mid {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 360px;
  max-width: 400px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out;
  left: 254px;
  top: 0px;
}

.gray-400 {
  color: #666666;
}
.custom-highlight-class {
  box-shadow:
    #0047ff 0px 0px 1px 2px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.max-w-718 {
  max-width: 718px;
}
.d-flex-center {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.custom-badge-tagtype {
  color: #475467 !important;
  background-color: #fff !important;
  border: 1px solid #475467 !important;
}

.img-20-w-h {
  width: 20px;
  height: 20px;
}
.d-inline-flex {
  display: inline-flex;
}
.text-grey-500 {
  color: #667085;
}
.w-125-px {
  width: 125px;
}
.h-40 {
  height: 40px;
}
.line-right {
  padding-top: 7px;
  color: #eaecf0;
}

.usage-box-home {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.w-148 {
  width: 148px;
}
.pt-9px {
  padding-top: 9px;
}

.close-icon-30 {
  width: 30px !important;
  height: 30px !important;
}

.badge-free {
  color: #fff;
  background-color: #acb8cb;
}

.badge-hash {
  color: #475467 !important;
  background-color: #d0d5dd;
}

.border-grey {
  border: 1px solid #eaecf0;
}

.long-text {
  width: 90%;
  overflow-wrap: break-word;
  white-space: normal;
}

.d-flex-start{
  display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.spinner-border {
  --bs-spinner-border-width: 0.15em !important;
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.btn-outline-dark-info{
color: #0047FF !important;
border:1px solid #0047FF !important;
background-color:transparent !important;
  display: inline-flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.btn-outline-dark-info:hover{
  color: #002EA5 !important;
  border:1px solid #002EA5 !important;
  background-color: transparent !important;
      display: inline-flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        align-content: center !important;
        justify-content: center !important;
        align-items: center !important;
}

.secondBox-meta {
width: 70%;
}

